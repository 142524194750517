// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-covid-policy-js": () => import("./../../../src/pages/covid-policy.js" /* webpackChunkName: "component---src-pages-covid-policy-js" */),
  "component---src-pages-gift-voucher-js": () => import("./../../../src/pages/gift-voucher.js" /* webpackChunkName: "component---src-pages-gift-voucher-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-information-and-conditions-js": () => import("./../../../src/pages/information-and-conditions.js" /* webpackChunkName: "component---src-pages-information-and-conditions-js" */),
  "component---src-pages-mothers-day-js": () => import("./../../../src/pages/mothers-day.js" /* webpackChunkName: "component---src-pages-mothers-day-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-why-js": () => import("./../../../src/pages/why.js" /* webpackChunkName: "component---src-pages-why-js" */),
  "component---src-templates-services-js": () => import("./../../../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */)
}

